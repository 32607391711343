<script>
  import {TabulatorFull as Tabulator} from 'tabulator-tables'; //import Tabulator library
  import "tabulator-tables/dist/css/tabulator_bootstrap3.min.css";
  import { mapMutations, mapState } from "vuex";
  export default {
    data() {
      return {
        tabulator: null, //variable to hold your table
        tableData: [], //data for table to display
      }
    },
    mounted() {
      //instantiate Tabulator when element is mounted
      const scope = this;
      this.tabulator = new Tabulator(this.$refs.table, {
        //data: this.$store.state.rawData, //link data to table
        reactiveData: true, //enable data reactivity
        pagination: true,
        paginationMode: 'remote',
        paginationSize: 50,
        paginationSizeSelector: [25, 50, 100, 200, 500],
        sortMode:"remote",
        filterMode:"remote",
        ajaxURL:"/api/generateRawTable", //set url for ajax request
        ajaxParams: {
          startDate: this.$store.state.rawData.startDate,
          endDate: this.$store.state.rawData.endDate
        },
        ajaxRequestFunc: async function(url, config, params) {
          let result = await scope.$axios.post(url, params);
          for (let c of result.data.data) {
            c.local = new Date(c.Horodatage).toLocaleString();
          }
          return result.data;
        },
        columns: [
          {
            field: 'ID_Pose',
            title: 'ID',
            width: 120,
            headerFilter: 'input'
          },
          {
            field: 'Machine',
            title: 'M',
            headerFilter: 'input',
            width: 10
          },
          {
            field: 'Capteur',
            title: 'C',
            headerFilter: 'input',
            width: 10
          },
          {
            field: 'Horodatage',
            title: 'Horodatage',
            headerFilter: 'input'
          },
          {
            field: 'local',
            title: 'Local'
          },
          {
            field: 'Action',
            title: 'A',
            headerFilter: 'input',
            width: 10
          },
          {
            field: 'actionStr',
            title: 'Action',
            headerFilter: 'input'
          },
          {
            field: 'Torque',
            title: 'Torque',
            headerFilter: 'input'
          },
          {
            field: 'torqueFixed',
            title: 'Calc',
            headerFilter: 'input'
          },
          {
            field: 'Pression',
            title: 'Pression',
            headerFilter: 'input'
          },
          {
            field: 'Laser',
            title: 'Laser',
            headerFilter: 'input'
          },
          {
            field: 'HAcc',
            title: 'Précision',
            headerFilter: 'input'
          },
          {
            field: 'Heading',
            title: 'Heading',
            headerFilter: 'input'
          },
          {
            field: 'GPS_Y',
            title: 'Latitude',
            headerFilter: 'input'
          },
          {
            field: 'GPS_X',
            title: 'Longitude',
            headerFilter: 'input'
          },
          {
            field: 'GPS_Z',
            title: 'Altitude',
            headerFilter: 'input'
          },
          {
            field: 'Angle_X',
            title: 'Angle_X',
            headerFilter: 'input'
          },
          {
            field: 'Angle_Y',
            title: 'Angle_Y',
            headerFilter: 'input'
          },
          {
            field: 'diffFromPrevious',
            title: 'Dist',
          },
          {
            field: 'map',
            title: 'Map',
            formatter:'html'
          }
        ], //define table columns
        //autoColumns:true,
      });
    }
  }
</script>

<template>
  <v-card style="height: 100%; width: 100%; border-radius: 20px; text-align-last: center;" elevation="0" class="pt-5">
    <v-btn fab max-height="40px" max-width="40px" @click="$router.push({ path: '/', replace: true })" style="position:absolute;z-index: 1000;" class="ml-1 theme--dark">
      <v-icon class="material-icons-round">mdi-arrow-u-left-top</v-icon>
    </v-btn>
    <v-btn fab max-height="40px" max-width="40px" @click="tabulator.replaceData()" style="position:absolute;z-index: 1000;right: 10px;" class="ml-1 techno-green">
      <v-icon class="material-icons-round">mdi-refresh</v-icon>
    </v-btn>
    <div ref="table"></div>
  </v-card>
</template>

<style>
input[type=search] {
  border: 1px solid lightgrey;
  border-radius: 3px;
}
.v-application a {
  color: #009f4d !important;
}
.techno-green {
  background-color:#009f4d!important;
  color: white!important;
}
</style>